import React from 'react'
import PropTypes from 'prop-types'
import Seo from '~components/Seo'
import Section from '~components/Section'
import styled from '@emotion/styled'
import { graphql, Link } from 'gatsby'
import DataViewer from '~utils/DataViewer'
import { mobile, tablet, desktop } from '~styles/global'

const Search = ({ data }) => {

	return (
		<>
			<Seo
				title="Chat"
			/>
			<style>
				{`
					body{
						background: #F3F1F0;
					}
				`}
			</style>
			<Wrap>
			 <Iframe 
				src="https://au.bot.joseflegal.com/P4QdovkB/dot" 
				style={{
					overflow: 'hidden',
					height: '100%',
					minWidth: '300px'
				}}
				frameborder="0"  />
			</Wrap>
		</>
	)
}

const Wrap = styled.div`
	background: #F3F1F0;
	padding: 40px;
	margin-right: 60px;
`

const Iframe = styled.iframe`
	min-height: 75vh;
	${mobile}{
		min-height: 650px;
	}
	border: none;
	box-sizing: border-box;
	width: 100%;
	background: white;
	border-radius: 6px;
`

Search.propTypes = {
	data: PropTypes.object,
}


export default Search
